<template>
  <div>
    <validation-observer
      ref="accountFormValidation"
      v-slot="{ invalid }"
    >
      <div
        v-if="loading"
        data-testid="create-ad-account-loading"
        class="create-loading"
      >
        <b-spinner
          variant="danger"
          style="margin-left: 10px"
        />
      </div>
      <b-form
        v-else
        data-testid="form-create-ad-account"
        @submit.prevent="submit"
      >
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
              data-testid="field-BCId-name"
              class="form-label"
            >
              <validation-provider
                #default="{ errors }"
                id="name"
                rules="required"
                :name="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group class="select-input">
                    <b-form-select
                      v-model="businessId"
                      data-testid="dropdown-BCId"
                      :disabled="checkPlanUserToDisableInput"
                      :aria-selected="businessId ? businessId.name : ''"
                    >
                      <b-form-select-option
                        v-for="(business, index) in listBusinessName"
                        :key="index"
                        :id="business.value.id"
                        :value="business.value"
                        :data-testid="business.value.id"
                      >
                        {{ business.text }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-input-group>
                <small
                  v-if="errors[0]"
                  class="text-danger d-block"
                  style="margin-top: 10px"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="9"
            cols="12"
          >
            <b-form-group
              :label="$t('myAdAccountsPage.setupTab.step3.textAccountName')"
              class="form-label"
              data-testid="field-account-name"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('myAdAccountsPage.setupTab.step3.textAccountName')"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="accountName"
                    data-testid="input-account-name"
                    class="input-height"
                    placeholder=""
                    :disabled="checkPlanUserToDisableInput"
                    :state="errors.length > 0 ? false : null"
                    @keyup="currentIndex = null"
                  />
                </b-input-group>
                <small
                  v-if="errors[0]"
                  data-testid="error-account-name"
                  class="text-danger d-block"
                  style="margin-top: 10px"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="3"
            cols="12"
          >
            <b-form-group
              :label="$t('account.currency')"
              data-testid="field-currency"
              class="form-label"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('account.currency')"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group class="select-input">
                    <b-form-select
                      v-model="currencySelected"
                      data-testid="select-currency"
                      :options="currencyOptions"
                      placeholder="Select currency"
                      disabled
                    />
                  </b-input-group>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group data-testid="field-timezone">
              <label data-testid="label-timezone">{{ $t('account.timeZone') }}</label>
              <validation-provider
                #default="{}"
                rules="required"
                :name="$t('account.timeZone')"
              >
                <b-input-group class="timezone-select">
                  <v-select
                    v-model="timeZone"
                    data-testid="timezone-select"
                    style="width: 100%"
                    label="name"
                    placeholder=""
                    :disabled="checkPlanUserToDisableInput"
                    :clearable="false"
                    :options="listTimeZone"
                  >
                    <template #open-indicator="{ attributes }">
                      <span
                        v-bind="attributes"
                        data-testid="chevron-icon"
                      >
                        <feather-icon
                          icon="ChevronDownIcon"
                          size="20"
                        />
                      </span>
                    </template>
                  </v-select>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-if="!businessInfo"
          data-testid="form-business-info"
        >
          <p
            class="mt-1 title-content-step"
          >
            {{ $t('profile.textGeneral') }}
          </p>
          <b-row>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                :label="$t('myAdAccountsPage.labelYourBusinessName')"
                data-testid="label-business-name"
                class="form-label"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('myAdAccountsPage.labelYourBusinessName')"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="yourBusinessName"
                      data-testid="input-business-name"
                      class="input-height"
                      placeholder=""
                      :disabled="checkPlanUserToDisableInput"
                      :state="errors.length > 0 ? false : null"
                      @keyup="currentIndex = null"
                    />
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    data-testid="error-business-name-step3"
                    class="text-danger d-block"
                    style="margin-top: 10px"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                :label="$t('myAdAccountsPage.labelCompanyWebsite')"
                data-testid="label-company-website"
                class="form-label"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="url|verifyBusinessWebsite|required"
                  :name="$t('myAdAccountsPage.labelCompanyWebsite')"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="companyWebsite"
                      data-testid="input-company-website"
                      class="input-height"
                      placeholder=""
                      :disabled="checkPlanUserToDisableInput"
                      :state="errors.length > 0 ? false : null"
                      :type="url"
                      @keyup="currentIndex = null"
                    />
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    data-testid="error-company-website"
                    class="text-danger d-block"
                    style="margin-top: 10px"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div v-if="false">
          <p
            v-if="errorType === 'need_to_at_least_one_approved_product_link'"
            class="text-danger"
          >
            {{ $t('myAdAccountsPage.contentErrorDoNotHaveProductLinks') }}
            <span
              class="text-blue underline cursor-pointer"
              @click="$router.push('/product-link')"
            >{{ $t('common.linkClickHere') }}</span>
            {{ $t('myAdAccountsPage.textToSeeAll') }}
          </p>
        </div>

        <div
          v-if="false"
          class="mb-2"
        >
          <b-row style="margin-top: 10px">
            <b-col>
              <h4 class="font-20">
                {{ $t('myAdAccountsPage.contentYourAvailableProductLinks') }}:
              </h4>
              <ul
                class="my-2"
                style="list-style: none; padding: 0"
              >
                <li
                  v-for="(item, i) in listApprovedUrls"
                  :key="i"
                  class="d-flex align-items-center"
                  style="margin-bottom: 5px"
                >
                  <p
                    class="
                      underline
                      cursor-pointer
                      font-14
                      text-secondary
                      mb-0
                      text-truncate
                    "
                    @click="goToUrl(item.productUrl)"
                  >
                    <b-img
                      size="21"
                      :src="require('@/assets/images/icons/ic-check.svg')"
                    />
                    {{ item.productUrl }}
                  </p>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row
            v-if="!setupGuideStep"
          >
            <b-col>
              <b-form-checkbox
                v-model="selected"
                class="custom-control-danger"
              >
                <span
                  class="font-14"
                >{{ $t('myAdAccountsPage.contentIWillUseProductLinks') }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>

        <div v-if="false">
          <b-row>
            <b-col cols="12">
              <b-form-group
                class="product-tag input-add-product"
                label-for="tags-validation"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('myAdAccountsPage.textProductUrl') }} <span class="text-danger">* </span>
                    <img
                      v-b-tooltip.v-light="{
                        title:
                          '<div>- The specific url for each product/service you would like to run ads. <br/>- The app download url you would like to run ads.</div>',
                        html: true,
                      }"
                      :src="require('@/assets/images/common/ic-help.svg')"
                      alt="image"
                      style="margin-top: -10px"
                    >
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="fullName"
                  rules="required|url"
                  :name="$t('myAdAccountsPage.textProductUrl')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="productsData.productURL"
                      name="product link"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{}"
                name="country"
                rules="required"
              >
                <b-form-group class="m-0 country-tag input-add-product">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      {{ $t('myAdAccountsPage.labelTargetMarket') }} <span class="text-danger">*</span>
                      <span class="font-small-2 text-placeholder">
                        ({{ $t('myAdAccountsPage.contentSubTargetMarket') }})</span>
                    </label>
                  </div>
                  <v-select
                    v-model="productsData.targetMarkets"
                    class="input-height"
                    multiple
                    :options="availableOptions"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <div
          v-if="false"
          class="d-flex flex-column warning-message mt-2"
        >
          <div class="d-flex">
            <img
              class="alert-img"
              alt="alert"
              :src="require('@/assets/images/common/ic-alert.svg')"
            >
            <small>
              {{ $t('myAdAccountsPage.contentWarning') }} ${{ minTopUp }} {{ $t('common.textIn') }}
              {{ platformName }} {{ $t('myAdAccountsPage.textAccountToBeAble') }}
              {{ $t('billingPage.banner.textPlease') }}
              <strong
                class="text-danger cursor-pointer"
                @click="goToTopUp"
              >{{ $t('myAdAccountsPage.textRechargeYourBalance') }}
              </strong>
            </small>
          </div>
          <p
            v-b-toggle.collapse-1
            class="cursor-pointer underline font-14 mb-0"
            style="margin-left: 29px; margin-top: 8px"
          >
            {{ $t('myAdAccountsPage.textWhyWeNeed') }} ${{ minTopUp }} {{ $t('myAdAccountsPage.textTopUp') }}?
          </p>
          <b-collapse
            id="collapse-1"
            class="mt-1 font-14"
            style="margin-left: 29px"
          >
            <div>
              {{ $t('myAdAccountsPage.textTheAimOf') }} ${{ minTopUp }} {{ $t('myAdAccountsPage.textTopUp') }}:
              <ul style="margin-top: 5px">
                <li>
                  {{ $t('myAdAccountsPage.firstItemContentWarning') }}
                  {{ platformName }}.
                </li>
                <li>
                  {{ $t('myAdAccountsPage.secondItemContentWarning') }}
                  {{ companyName }}'s {{ $t('myAdAccountsPage.textAvoidSituations') }}
                  {{ companyName }}'s {{ $t('myAdAccountsPage.textAccountWithViolation') }}
                </li>
                <li>{{ $t('myAdAccountsPage.thirdContentWarning') }}</li>
                <li>
                  {{ $t('myAdAccountsPage.finalContentWarning') }}
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>

        <div
          v-if="message"
        >
          <error-tag>
            <template #errorMessenger>
              <p
                data-testid="error-messenger-nee-add-fund"
                class="mb-0"
                style="margin-left: 8px"
              >
                {{ message }}
                <span v-if="needAddFundMore">
                  {{ $t('myAdAccountsPage.textClick') }}
                  <span
                    data-testid="hyperlink-add-balance"
                    class="cursor-pointer underline"
                    style="color: #2667ff;"
                    @click.prevent="navigateAddBalance"
                  >{{ $t('myAdAccountsPage.textAddBalance') }}</span>
                  {{ $t('myAdAccountsPage.textContinueCreateAdAccount') }}
                </span>
              </p>
            </template>
          </error-tag>
        </div>
        <div
          v-if="checkPlanUserToDisableInput"
        >
          <error-tag>
            <template #errorMessenger>
              <p
                v-if="subscriptionMode"
                data-testid="error-messenger-limit-ad"
                class="mb-0"
                style="margin-left: 8px"
              >
                {{ $t('myAdAccountsPage.errorPackageLimitation') }}
                <span v-if="checkBtnUpgradePlan && !isMember">
                  {{ $t('myAdAccountsPage.textClick') }}
                  <span
                    data-testid="hyperlink-upgrade-plan"
                    class="cursor-pointer underline"
                    style="color: #2667ff;"
                    @click.prevent="navigateUpgradePlan"
                  >{{ $t('myAdAccountsPage.textUpgradePlan') }}</span>
                  {{ $t('myAdAccountsPage.textCreateMoreAccount') }}
                </span>
              </p>
              <p
                v-else
                data-testid="error-messenger-limit-ad"
                class="mb-0"
              >
                {{ $t('myAdAccountsPage.errorLimitation') }}
              </p>
            </template>
          </error-tag>
        </div>

        <div class="mt-2 d-flex justify-content-end">
          <btn-loading
            id="trigger-create-new-account-setting"
            span-id="trigger-create-new-account-setting-child"
            data-testid="btn-create-ad-account"
            class="btn-height btn-text-color width-button ml-1"
            type="submit"
            variant-convert="btn-submit"
            pill
            :disabled="invalid"
            :loading="loading"
          >
            {{ $t('myAdAccountsPage.approvedTab.textCreateNewAccount') }}
          </btn-loading>
        </div>
      </b-form>
    </validation-observer>

    <add-product-link-modal ref="open-add-product-link-modal" />
  </div>
</template>
<script>
import {
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BRow,
  BSpinner,
  BFormSelectOption,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required, url, verifyBusinessWebsite } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import partnerMixin from '@/mixins/partnerMixin'
import envMixin from '@/mixins/envMixin'
import { toastification } from '@core/mixins/toast'
import adsAccount from '@/mixins/adsAccount'
import _isEmpty from 'lodash/isEmpty'
import {
  LIMIT_TO_CREATE_NEW_ADS_ACCOUNT,
  PROFILE_STATUS,
  SUBSCRIPTION_TYPE,
  TERMS_LINK,
} from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import _get from 'lodash/get'
import errorTag from '@/components/errorTag.vue'
import AddProductLinkModal from './AddProductLinkModal.vue'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('adsAccount')
const {
  mapGetters: mapGettersAuth,
  mapActions: mapActionsAuth,
} = createNamespacedHelpers('auth')
const { mapActions: mapActionsProductLinks } = createNamespacedHelpers('productLinks')

export default {
  components: {
    errorTag,
    BtnLoading,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCollapse,
    BSpinner,
    vSelect,
    BFormCheckbox,
    AddProductLinkModal,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  mixins: [
    generalConfigsMixin,
    toastification,
    adsAccount,
    billingMixin,
    envMixin,
    stepGuideMixin,
    partnerMixin,
  ],

  data() {
    return {
      required,
      verifyBusinessWebsite,
      url,
      businessId: null,
      accountName: null,
      yourBusinessName: null,
      companyWebsite: null,
      storeUrl: null,
      currencySelected: 'USD',
      timeZone: '',
      limitToCreateAccount: LIMIT_TO_CREATE_NEW_ADS_ACCOUNT,
      termsLink: TERMS_LINK,
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      selected: false,
      errorType: null,

      needAddFundMore: false,
    }
  },

  computed: {
    ...mapGetters(['listAccount', 'listBusinessAccounts', 'loading', 'message', 'status', 'error']),
    ...mapGettersAuth(['user', 'plansStatus']),

    businessInfo() {
      return this.user?.data?.businessInfo
    },

    checkBtnUpgradePlan() {
      return (this.whiteAgencyPlan && !this.hasStepGuide)
        || (this.activePlanUser && this.isLimitAccount)
        || this.isLimitAccount
    },

    checkPlanUserToDisableInput() {
      return this.whiteAgencyPlan
        || (this.activePlanUser && this.isLimitAccount)
        || this.isLimitAccount
    },

    activePlanUser() {
      const { activePlan } = this.plansStatus
      return activePlan === SUBSCRIPTION_TYPE.black_agency
        || activePlan === SUBSCRIPTION_TYPE.enterprise
        || this.isMember
    },

    whiteAgencyPlan() {
      const { activePlan } = this.plansStatus
      return activePlan === SUBSCRIPTION_TYPE.white_agency
    },

    isLimitAccount() {
      return this.totalAdsAccount === this.adsAccountLimit
    },

    totalAdsAccount() {
      return _get(this.listAccount, ['paging', 'total'], 0)
    },

    adsAccountLimit() {
      return _get(this.user, ['data', 'plans', 'adsAccountLimit'], 0)
    },

    currencyOptions() {
      return this.listCurrency.filter(item => item === 'USD')
    },

    listBusinessName() {
      const listOption = this.listBusinessAccounts.list.map(item => ({
        value: {
          id: item.bc_info.bc_id,
          name: item.bc_info.name,
        },
        text: item.bc_info.name,
      }))

      return [...listOption]
    },

    availableOptions() {
      return this.listTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    hasProductLink() {
      const {
        products,
        whitelistProductVerify,
      } = this.user?.data
      return !_isEmpty(products?.productURLs) || whitelistProductVerify
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    listProductLink() {
      const listProductUrl = this.user?.data?.products?.productURLs || {}

      const arrUrlConverted = Object.keys(listProductUrl)
        .map(value => ({
          productUrl: value,
          ...listProductUrl[value],
        }))

      return arrUrlConverted || []
    },

    listApprovedUrls() {
      return (
        this.listProductLink.filter(
          productUrl => productUrl.status === PROFILE_STATUS.APPROVED,
        ) || []
      )
    },

    hasApprovedUrls() {
      return (
        this.listApprovedUrls
        && Array.isArray(this.listApprovedUrls)
        && this.listApprovedUrls.length > 0
      )
    },

    isShowWarning() {
      return this.user?.data?.balance < this.minTopUp
    },
  },

  // watch: {
  // listBusinessName: {
  //   handler(item) {
  //     console.log({ item })
  //     if (Array.isArray(item) && item.length > 0) {
  //       // eslint-disable-next-line prefer-destructuring
  //       this.businessId = item[0]
  //     }
  //   },
  //   deep: true,
  //   immediate: true,
  // },
  // },

  created() {
    this.setTimezone()
  },

  methods: {
    ...mapActions(['createAdsAccount', 'getAdsAccount', 'createAdsAccountNew']),
    ...mapActionsAuth(['updateAccount']),
    ...mapActionsProductLinks(['createProductLink']),

    setTimezone() {
      if (this.isEmediaPlatform) {
        this.timeZone = '(UTC+00:00) Africa/Casablanca'
      } else {
        this.timeZone = ''
      }
    },

    openAddProductLinksModal() {
      this.$refs['open-add-product-link-modal'].showModal()
    },

    resetForm() {
      this.businessId = ''
      this.timeZone = '(UTC+07:00) Asia/Ho_Chi_Minh'
      this.currencySelected = 'USD'
      this.accountName = ''
      // this.$refs.accountFormValidation.reset()
    },

    goToTopUp() {
      this.$router.push('/payment/add-fund')
    },

    submit() {
      this.$refs.accountFormValidation.validate()
        .then(async success => {
          if (success) {
            if (this.noPlan) {
              if (this.isTikTokAppCenter) {
                const idToken = localStorage.getItem('id_token')
                window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
              } else {
                this.$router.push('/billing')
              }
            } else {
              const params = {
                businessId: this.businessId.id,
                partnerBCName: this.businessId.name,
                timezone: this.timeZone,
                name: this.accountName.toString(),
                companyName: this.yourBusinessName,
                companyWebsite: this.companyWebsite,
              }

              await this.createAdsAccountNew(params)

              if (this.status) {
                this.resetForm()
                this.toastSuccess(this.$t('myAdAccountsPage.setupTab.step3.noticeCreateNewAccountSuccess'))
                window.location.href = '/my-ads-account?tab=approved'
                localStorage.removeItem('isHasOnboardingStep')
              } else if (this.error?.data?.code === 'error.need_top_up_money') {
                this.needAddFundMore = true
              }
            }
          }
        })
    },
    navigateUpgradePlan() {
      if (this.isTikTokAppCenter) {
        const idToken = localStorage.getItem('id_token')
        window.open(`${process.env.VUE_APP_SITE_URL}/pricing?id_token=${idToken}`)
      } else {
        this.$router.push('/pricing')
      }
    },

    navigateAddBalance() {
      if (this.isTikTokAppCenter) {
        const idToken = localStorage.getItem('id_token')
        if (this.noPlan) {
          window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
        } else if (this.isOnlyEcomdyPlatform) {
          window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?tab=paypal&id_token=${idToken}`)
        } else {
          window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?id_token=${idToken}`)
        }
      } else if (this.noPlan) {
        this.$router.push('/billing')
      } else if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.timezone-select {
  .vs--single .vs__dropdown-toggle {
    height: 48px;
  }
}

.country-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .custom-select:focus {
    border-color: #16213e !important;
  }
}

.product-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .card {
    border-radius: 0.625rem;
  }

  .card-body {
    padding: 0.86rem 1rem;
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 5.09px 0 6.5px 0;
  }
}
</style>

<style lang="scss" scoped>
.select-input {
  .custom-select {
    &:focus {
      border-color: #16213e;
    }
  }
}

.input-add-product {
  margin-bottom: 19px;
}

.is-invalid .select-input {
  select {
    border-color: red;
  }
}

.link {
  color: #2667ff;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.alert-img {
  margin-right: 10px;
}

.create-loading {
  height: 300px;
  position: relative;

  span {
    position: absolute;
    top: 45%;
    left: 45%;
  }
}

.timezone-select {
  .v-select {
    width: 100%;
  }
}

.width-button {
  @media(max-width: 767px) {
    width: 100%;
  }
}

.error-create-ads {
  margin-top: 40px;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}
</style>
